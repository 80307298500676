define("builder/services/preview", ["exports", "builder/config/environment", "builder/core/enumerators/cookie"], function (_exports, _environment, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PreviewService
   */
  var _default = Ember.Service.extend({
    /**
     * @property {string} domain - Application domain
     */
    domain: _environment.default.previewDomain,

    /**
     * @property {string} subdomain - Sub-domain, basically used on CI
     * @default ['']
     */
    subdomain: '',

    /**
     * @property {null|String} cssBreakpoint - contain bootstrap css breakpoint(lg, md and etc)
     */
    cssBreakpoint: 'lg',

    /**
     * @property {string} url - Full URL to preview service
     */
    url: Ember.computed(function () {
      return `${window.location.protocol}//preview-${this.subdomain}${this.domain}`;
    }),

    /**
     * @property {ConfigurationService} pageService - page service
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * @property {Boolean} rebuild - get parameter for module preview
     */
    rebuild: true,

    /**
     * Generates error message
     *
     * @param {String} subject - Part of error message
     * @returns {string}
     * @private
     */
    _generateWarning(subject) {
      return `${subject} is required to get module preview`;
    },

    /**
     * Returns URL to get preview of module
     *
     * @method previewUrl
     * @public
     * @param {Object} getParams - Get params for module preview
     * @param {Number} getParams.moduleId - Module ID
     * @param {Number} getParams.reload - Flag to reload preview
     * @returns {string} URL to get preview of module
     */
    previewUrl(getParams) {
      if (!getParams.moduleId) {
        throw new Ember.Error(this._generateWarning('Module id'));
      }

      const params = [`pageId=${this.get('configurationService.page.Alias')}`, `websiteId=${this.get('configurationService.website.id')}`, `moduleId=${getParams.moduleId}`, `domain=${_environment.default.previewDomain}`, `${_cookie.default.SESSION_ID}=${this.get('configurationService.sessionId')}`];

      if (getParams.hasOwnProperty('reload')) {
        params.push(`reload=${getParams.reload}`);
      }

      if (this.rebuild) {
        params.push('rebuild=true');
        this.set('rebuild', false);
      }

      return `${this.url}/?${params.join('&')}`;
    },

    /**
     * @property {string} bootstrapUrl - URL of compiled Bootstrap
     */
    bootstrapUrl: Ember.computed('url', 'cssBreakpoint', function () {
      return `${this.url}/bootstrap?websiteId=${this.get('configurationService.website.id')}&size=${this.cssBreakpoint}`;
    }),

    init(...args) {
      this._super(...args);

      this._setSubdomain();
    },

    /**
     * Determine sub-domain
     *
     * @method _setSubdomain
     * @private
     */
    _setSubdomain() {
      const subdomain = window.document.location.host.match(new RegExp(`(.+)${this.domain}$`));

      if (subdomain !== null && subdomain[1]) {
        this.set('subdomain', subdomain[1]);
      }
    }

  });

  _exports.default = _default;
});